import { FC, Fragment } from 'react';
import { WorkingHoursData } from '../ts/interfaces/Generic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

interface ContactCenterProps {
  workingHours: WorkingHoursData;
}

const ContactCenter: FC<ContactCenterProps> = ({ workingHours }) => {
  return (
    <div className="contact-center">
      {workingHours.Office && (
        <Fragment>
          <div className="contact-center-header">{workingHours.Office.Title}</div>
          <div className="contact-center-content">
            {workingHours.Office.WorkingHours_1 && <div>{workingHours.Office.WorkingHours_1}</div>}
            {workingHours.Office.WorkingHours_2 && <div>{workingHours.Office.WorkingHours_2}</div>}
            {workingHours.Office.Phone_1 && (
              <div>
                <a href={'tel:' + workingHours.Office.Phone_1}>
                  {workingHours.Office.Phone_1}
                  <FontAwesomeIcon icon={faPhone} />
                </a>
              </div>
            )}
            {workingHours.Office.Phone_2 && (
              <div>
                <a href={'tel:' + workingHours.Office.Phone_2}>
                  {workingHours.Office.Phone_2}
                  <FontAwesomeIcon icon={faPhone} />
                </a>
              </div>
            )}
            {workingHours.Office.Email && (
              <div>
                <a href={'mailto:' + workingHours.Office.Email}>
                  {workingHours.Office.Email}
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </div>
            )}
          </div>
        </Fragment>
      )}
      {workingHours.Emergency && (
        <Fragment>
          <div className="contact-center-header contact-center-emergency">
            {workingHours.Emergency.Title}
          </div>
          <div className="contact-center-content">
            {workingHours.Emergency.WorkingHours_1 && (
              <div>{workingHours.Emergency.WorkingHours_1}</div>
            )}
            {workingHours.Emergency.WorkingHours_2 && (
              <div>{workingHours.Emergency.WorkingHours_2}</div>
            )}
            {workingHours.Emergency.Phone_1 && (
              <div>
                <a href={'tel:' + workingHours.Emergency.Phone_1}>
                  {workingHours.Emergency.Phone_1}
                  <FontAwesomeIcon icon={faPhone} />
                </a>
              </div>
            )}
            {workingHours.Emergency.Phone_2 && (
              <div>
                <a href={'tel:' + workingHours.Emergency.Phone_2}>
                  {workingHours.Emergency.Phone_2}
                  <FontAwesomeIcon icon={faPhone} />
                </a>
              </div>
            )}
            {workingHours.Emergency.Email && (
              <div>
                <a href={'mailto:' + workingHours.Emergency.Email}>
                  {workingHours.Emergency.Email}
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ContactCenter;
