export const heTranslation = {
  site_name: 'Enigma Hotels',
  transfer: {
    distance: 'מרחק',
  },
  flight: {
    dates: 'תאריכים',
    flight: 'טיסה',
    departure: 'יציאה',
  },
  package: {
    general_conditions: 'תנאים כלליים',
  },
};
