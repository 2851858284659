import { DetailsHotelPage } from '@bepro-travel/fe.hotel';
import { ScreenshotSupport } from '@bepro-travel/fe.shared';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DetailsHotelPageModalProps {
  onClose: () => void;
}

const DetailsHotelPageModal: FC<DetailsHotelPageModalProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div className="modal-overlay modal-overlay-top">
      <div className="modal active">
        <div className="modal-title">
          <span></span>
          <div>
            <ScreenshotSupport />
            <FontAwesomeIcon icon={faTimes} onClick={props.onClose} />
          </div>
        </div>
        <div className="modal-content">
          <DetailsHotelPage />
        </div>
        <div className="modal-footer">
          <button className="btn btn-default" onClick={props.onClose}>
            {t('close')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailsHotelPageModal;
