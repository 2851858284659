import { Validation } from '@bepro-travel/fe.utils';
import i18n from '../utils/i18n';
export const googleMapsKey = 'AIzaSyBxEwtMRXpcXdcMScNSUAF6G6sr9wwf3cA';
const { protocol, hostname } = window.location;

export const cdnUrl = process.env.REACT_APP_DEBUG
  ? 'https://enigma-hotels.com'
  : `${protocol}//${hostname}`;
export const baseUrl = cdnUrl + '/backend';
export const blobUrl = cdnUrl + '/backend/s3/prod.beprotravel/cdn/';

export const colorContrast = '#191e3b';
export const colorBorder = '#ddd';
export const colorDark = '#666';
export const colorStar = '#ffb700';
export const colorSuccess = '#4caf50';
export const colorDanger = '#f44336';
export const mapSupplier = 'google' as 'google' | 'osm';

export const getSortHotelOptions = () => [
  { value: 'recommended', label: i18n.t('sort.recommended') },
  { value: 'priceIncrease', label: i18n.t('sort.price_increase') },
  { value: 'priceDecrease', label: i18n.t('sort.price_decrease') },
  { value: 'starIncrease', label: i18n.t('sort.star_increase') },
  { value: 'starDecrease', label: i18n.t('sort.star_decrease') },
  { value: 'nameIncrease', label: i18n.t('sort.hotel_increase') },
  { value: 'nameDecrease', label: i18n.t('sort.hotel_decrease') },
  { value: 'distanceIncrease', label: i18n.t('transfer.distance') },
];

export const sexOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

export const getGender = () => {
  return [
    { value: 'Male', label: i18n.t('male') },
    { value: 'Female', label: i18n.t('female') },
  ];
};

export const credentialsValidation: Validation = {
  username: { required: true },
  password: { required: true },
};

export const imgsCar = [
  require('../assets/img/vehicles/Private-Car-Regular.png'),
  require('../assets/img/vehicles/Private-Car-Luxury.png'),
  require('../assets/img/vehicles/Minivan-Shuttle.png'),
  require('../assets/img/vehicles/Minibus.png'),
  require('../assets/img/vehicles/Bus.png'),
  require('../assets/img/vehicles/Private-Car-Business.png'),
  require('../assets/img/vehicles/Minivan-Regular.png'),
  require('../assets/img/vehicles/Handicap.png'),
  require('../assets/img/vehicles/Private-Driver.png'),
  require('../assets/img/vehicles/Private-Car-Regular.png'),
  require('../assets/img/vehicles/Limousine.png'),
];

export const hotelConfigImage = {
  ci: require('../assets/img/hotel-config/c_i.png'),
  ct: require('../assets/img/hotel-config/c_t.png'),
  cig: require('../assets/img/hotel-config/c_i_g.png'),
  ctg: require('../assets/img/hotel-config/c_t_g.png'),
  p1i: require('../assets/img/hotel-config/p1_i.png'),
  p1t: require('../assets/img/hotel-config/p1_t.png'),
  p1ig: require('../assets/img/hotel-config/p1_i_g.png'),
  p1tg: require('../assets/img/hotel-config/p1_t_g.png'),
  p2i: require('../assets/img/hotel-config/p2_i.png'),
  p2t: require('../assets/img/hotel-config/p2_t.png'),
  p2ig: require('../assets/img/hotel-config/p2_i_g.png'),
  p2tg: require('../assets/img/hotel-config/p2_t_g.png'),
  bi: require('../assets/img/hotel-config/b_i.png'),
  bt: require('../assets/img/hotel-config/b_t.png'),
  big: require('../assets/img/hotel-config/b_i_g.png'),
  btg: require('../assets/img/hotel-config/b_t_g.png'),
  pri: require('../assets/img/hotel-config/pr_i.png'),
  prt: require('../assets/img/hotel-config/pr_t.png'),
  prig: require('../assets/img/hotel-config/pr_i_g.png'),
  prtg: require('../assets/img/hotel-config/pr_t_g.png'),
};

export const uiConfig = { colorBorder, colorContrast, colorStar };

export const hotelConfig = {
  googleMapsKey,
  mapSupplier,
  imgBgImg: require('../assets/img/newbg.jpg'),
  imgBluePoint: require('../assets/img/blue.png'),
  imgRedPoint: require('../assets/img/red.png'),
  imgWaypointA: require('../assets/img/waypoint-a.png'),
  imgWaypointB: require('../assets/img/waypoint-b.png'),
  imgCenterPoint: require('../assets/img/center.png'),
  imgLoaderMain: require('../assets/img/loader.gif'),
  imgPaymentLoader: require('../assets/img/loader.gif'),
  cdnUrl,
  getPage: () => Promise.resolve({}),
  setFooter: () => {},
  setHeader: () => {},
  setSiteConfig: () => {},
  b2b: true,
  needToScreenshot: true,
  showConfig: true,
};
