import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enTranslation } from '../locales/en';
import { heTranslation } from '../locales/he';
import { i18nHotelConfig, enHotelTranslation, heHotelTranslation } from '@bepro-travel/fe.hotel';
import { i18nConfig } from '@bepro-travel/fe.ui';
import { enSharedTranslation, heSharedTranslation } from '@bepro-travel/fe.shared';
import { format } from 'date-fns';
import { enUS, he } from 'date-fns/locale';

export const dateFormat = (date: Date | string | number, formatStr: string) => {
  const lang = localStorage.getItem('language');

  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return format(dateObj, formatStr, { locale: lang === 'he' ? he : enUS });
};

export const i18nconfig = () => {
  const lang = 'en';
  localStorage.setItem('language', lang);

  const en = {
    ...enTranslation,
    ...enHotelTranslation,
    ...enSharedTranslation,
  };

  const he = {
    ...heTranslation,
    ...heHotelTranslation,
    ...heSharedTranslation,
  };

  i18nHotelConfig({ enTranslation: en, heTranslation: he });
  i18nConfig({ enTranslation: he, heTranslation: he });

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      he: {
        translation: he,
      },
    },
    lng: lang ? lang : 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  });
};

export default i18n;
