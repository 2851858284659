import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CredentialsModel } from '../ts/classes/CredentialsModel';
import { Button, FormGroup } from '@bepro-travel/fe.ui';
import { credentialsValidation as validation } from '../constants/generic';
import { validate } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import { showError, showSuccess } from '@bepro-travel/fe.utils';
import { MainContext } from '../contexts/MainContext';
import { useRecaptcha } from '@bepro-travel/fe.hooks';
import { auth } from '@bepro-travel/fe.shared';

const LoginPage = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { setUser, setUserSiteConfig } = useContext(MainContext);
  const [form, setForm] = useState(new CredentialsModel());
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { handleRecapcha } = useRecaptcha();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    const recaptchaToken = await handleRecapcha();
    if (!recaptchaToken) return;

    if (validate(validation, form)) {
      setIsLoading(true);

      const user = await auth.login({ ...form, recaptchaToken });

      if (user) {
        navigate('/hotels');
        showSuccess(t('login.success'));
        setUser(user);
        if (user.config) {
          setUserSiteConfig(user.config);
        }
      } else {
        showError(t('login.error'));
      }

      setIsLoading(false);
    }
  };

  return (
    <form className="login-wrapper" onSubmit={onSubmit}>
      <div className="title mb-30">{t('login.login_your')}</div>
      <FormGroup
        onChange={(username) => setForm({ ...form, username })}
        value={form.username}
        label={t('login.usercode')}
        validation={validation.username}
        isSubmitted={isSubmitted}
        name="username"
      />
      <FormGroup
        onChange={(password) => setForm({ ...form, password })}
        value={form.password}
        label={t('login.password')}
        validation={validation.password}
        isSubmitted={isSubmitted}
        type="password"
        name="password"
      />
      <Button
        title={t('login.login')}
        className="btn btn-primary w100p btn-st"
        disabled={isLoading}
      />
    </form>
  );
};

export default LoginPage;
