import { cdnUrl } from '../constants/generic';
import { InitSiteData } from '../ts/interfaces/Generic';

export const initLoader = () => {
  const loader = {
    loadingPage: {
      systemProducts: 'Hotels',
      systemProductsDetails: 'Loading',
      listOfGalleryId: [
        {
          code: '/s3/prod.beprotravel/135/BeTop/CMSGALLERY/loading/loader_c3a410fd.gif',
          alt: '',
        },
      ],
    },
  };
  sessionStorage.setItem('allLoadings', JSON.stringify({ loadings: [loader] }));
};

export const initCSSVars = (siteData: InitSiteData) => {
  document.querySelector('.header-logo')?.setAttribute('src', cdnUrl + siteData.logoUrl);

  if (siteData.companyId === 135) {
    document.body.classList.add('enigma');
  } else if (siteData.companyId === 125) {
    document.body.classList.add('ofakim');
  } else if (siteData.companyId === 150) {
    document.body.classList.add('ofakim');
  } else if (siteData.companyId === 138) {
    document.body.classList.add('walla');
  }

  if (siteData.styles) {
    for (const key in siteData.styles) {
      if (key && siteData.styles[key]) {
        document.documentElement.style.setProperty(key, siteData.styles[key]);
      }
    }
  }

  // document.documentElement.style.setProperty('--color-contrast', '#149300');
  // document.documentElement.style.setProperty('--color-contrast-2', '#fff');
  // document.documentElement.style.setProperty('--color-contrast-rgb', '25, 30, 59');
  // document.documentElement.style.setProperty(
  //   '--color-contrast-light',
  //   'rgba(218, 234, 255, 0.7)'
  // );
  // document.documentElement.style.setProperty('--border-radius', '15px');
  // document.documentElement.style.setProperty('--border-radius-md', '20px');
  // document.documentElement.style.setProperty('--border-radius-lg', '25px');
  //OFAKIM
};
