import {
  Validation,
  ValidationBody,
  ValidationMessages,
  ValidationRules,
} from '../ts/interfaces/Validation';

export const messages: ValidationMessages = {
  numbers: 'Only numbers are allowed',
  email: 'Incorrect email',
  required: 'Field is mandatory',
  minLength: 'Field must contain {1} characters or more',
  maxLength: 'Field must contain {1} characters or less',
  length: 'Field must contain {1} characters',
  english: 'Only english characters are allowed',
};

export const allRules: ValidationRules = {
  numbers: /^([-.0-9]+|^)$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  required: /\S+/,
  minLength: (length: number, value: string): boolean => {
    return !!(length && value.length >= length);
  },
  maxLength: (length: number, value: string): boolean => {
    return !(length && value.length > length);
  },
  length: (length: number, value: string): boolean => {
    return !!(length && value.length === length);
  },
  english: /^[a-zA-Z\s]*$/,
};

export const getErrorInField = (
  rules: ValidationBody,
  value: string,
  isSubmitted: boolean
): string | undefined => {
  if (isSubmitted) {
    for (const key of Object.keys(rules)) {
      const keyAsRule = key as keyof ValidationRules;
      const isRuleFn: boolean = typeof allRules[keyAsRule] === 'function';
      const isRegExp: boolean = allRules[keyAsRule] instanceof RegExp;
      if (
        (isRuleFn && !allRules[keyAsRule](rules[key as keyof ValidationBody], value || '')) ||
        (isRegExp && rules[key as keyof ValidationBody] && !allRules[keyAsRule].test(value || ''))
      ) {
        return messages[key as keyof ValidationMessages].replace(
          '{1}',
          rules[key as keyof ValidationBody] as string
        );
      }
    }
  }
};

export const isFieldValid = (rule: any, value: string | number, isSubmitted: boolean): string => {
  const parsedValue = setValue(value);
  const error = getErrorInField(rule, parsedValue, isSubmitted);
  return error || '';
};

export const validate = (fields: Validation, values: any): boolean => {
  let areValid: boolean[] = [];
  for (let fieldName in fields) {
    const value = setValue(values[fieldName]);
    const rules = fields[fieldName];

    for (const key of Object.keys(rules)) {
      const keyAsRule: keyof ValidationRules = key as keyof ValidationRules;
      const isRuleFn: boolean = typeof allRules[keyAsRule] === 'function';
      const isRegExp: boolean = allRules[keyAsRule] instanceof RegExp;
      areValid.push(
        !(isRuleFn && !allRules[keyAsRule](rules[keyAsRule], value)) &&
          !(isRegExp && rules[keyAsRule] && !allRules[keyAsRule].test(value))
      );
    }
  }
  return areValid.every((valid) => valid);
};

const setValue = (value: any): string => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number' && value !== 0) {
    return value.toString();
  } else if (value instanceof Date) {
    return value.toString();
  } else {
    return '';
  }
};
