import { FC, Fragment, useContext, useEffect } from 'react';
import { MainContext } from '../contexts/MainContext';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  BookHotelPage,
  BookPayment,
  DetailsHotelPage,
  HotelConfigContext,
  ResultHotelPage,
  SuccessHotelPage,
} from '@bepro-travel/fe.hotel';
import DetailsHotelPageModal from './DetailsHotelPageModal';
import MainPage from '../pages/MainPage';

interface HotelPagesProps {
  isEnigma: boolean;
  hotelIndex?: number;
}

const ResultHotel = () => {
  const { proposals, setProposals } = useContext(MainContext);
  return <ResultHotelPage proposals={proposals} setProposals={setProposals} />;
};

export const HotelPages: FC<HotelPagesProps> = ({ isEnigma, hotelIndex }) => {
  const [urlParams, setUrlParams] = useSearchParams();
  const { pathname, ...location } = useLocation();
  const strHotelIndex = hotelIndex ? hotelIndex : '';
  const { userSiteConfig } = useContext(HotelConfigContext);

  useEffect(() => {
    if (
      [`/hotel${strHotelIndex}`, `/hotels${strHotelIndex}`].some(
        (path) => pathname.replace(/(\/[^/]*).*/, '$1') === path
      )
    ) {
      setTimeout(() => {
        sessionStorage.setItem(
          'latestHotelPath' + strHotelIndex,
          JSON.stringify({ ...location, pathname, time: new Date() })
        );
      }, 1000);
    }
  }, [pathname]); // eslint-disable-line

  const onCloseDetails = () => {
    const newParams = Object.fromEntries(urlParams);
    delete newParams.details;

    setUrlParams(newParams);
  };

  return (
    <Fragment>
      <div
        style={{
          display: pathname === `/hotels${strHotelIndex}` ? 'block' : 'none',
        }}
      >
        <MainPage isEnigma={isEnigma} />
      </div>
      <div style={{ display: pathname === `/hotels${strHotelIndex}/results` ? 'block' : 'none' }}>
        <ResultHotel />
      </div>
      {!userSiteConfig.base?.openDetailsInNewTab && (
        <div
          style={{
            display:
              pathname === `/hotels${strHotelIndex}/results` && location.search.includes('details=')
                ? 'block'
                : 'none',
          }}
        >
          <DetailsHotelPageModal onClose={onCloseDetails} />
        </div>
      )}

      <div style={{ display: pathname === `/hotel${strHotelIndex}/book` ? 'block' : 'none' }}>
        <BookHotelPage />
      </div>
      {userSiteConfig.base?.openDetailsInNewTab && (
        <div style={{ display: pathname === `/hotel${strHotelIndex}/details` ? 'block' : 'none' }}>
          <DetailsHotelPage />
        </div>
      )}
      <div style={{ display: pathname === `/hotel${strHotelIndex}/payment` ? 'block' : 'none' }}>
        <BookPayment />
      </div>
      <div style={{ display: pathname === `/hotel${strHotelIndex}/success` ? 'block' : 'none' }}>
        <SuccessHotelPage />
      </div>
    </Fragment>
  );
};
