import { createContext } from 'react';
import { Hotel } from '../ts/interfaces/HotelResponse';
import { User } from '@bepro-travel/fe.shared';

export const MainContext = createContext({
  proposals: [] as Hotel[],
  setProposals: (_proposals: Hotel[]) => {},
  mapSupplier: 'google' as 'google' | 'osm',
  user: undefined as User | undefined,
  setUser: (_user?: User) => {},
  setUserSiteConfig: (_config: any) => {},
});
