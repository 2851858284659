export const enTranslation = {
  site_name: 'Enigma Hotels',
  transfer: {
    distance: 'Distance',
  },
  flight: {
    dates: 'Dates',
    flight: 'Flight',
    departure: 'Departure',
  },
  package: {
    general_conditions: 'General Conditions',
  },
};
