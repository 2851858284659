import React, { FC, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { cdnUrl } from '../constants/generic';

interface FooterProps {
  logoUrl: string;
}

const Footer: FC<FooterProps> = ({ logoUrl }) => {
  const location = useLocation();

  return (
    <Fragment>
      {!location.pathname.includes('results') && !location.pathname.includes('my-orders') && (
        <div className="footer-wrapper">
          <div className="footer">
            <div className="col-md-3 col-6">
              <img className="footer-logo" src={cdnUrl + logoUrl} alt="Logo" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Footer;
