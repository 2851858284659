import { FC, useEffect, useState } from 'react';
import { getRandomImage, getWorkingHours } from '../services/config';
import { MainHotelPage } from '@bepro-travel/fe.hotel';
import { cdnUrl } from '../constants/generic';
import { WorkingHoursData } from '../ts/interfaces/Generic';
import ContactCenter from '../components/ContactCenter';
import { useLocation } from 'react-router-dom';

interface MainCustomageProps {
  isEnigma: boolean;
}
const MainCustomage: FC<MainCustomageProps> = ({ isEnigma }) => {
  const [workingHours, setWorkingHours] = useState<WorkingHoursData>();
  const location = useLocation();
  useEffect(() => {
    (async () => {
      if (location.pathname !== '/' && location.pathname !== '/login') {
        return;
      }

      if (!isEnigma) {
        const response = await getRandomImage();
        const elem = document.querySelector('.main-page.hotels') as HTMLElement;
        elem.style.backgroundImage = `url(${cdnUrl + response?.imageUrl})`;

        const elemLogin = document.querySelector('.login-top') as HTMLElement;
        elemLogin.style.backgroundImage = `url(${cdnUrl + response?.imageUrl})`;
      }

      const data = await getWorkingHours();
      try {
        const workingHours = JSON.parse(data.officeInformation);
        setWorkingHours(workingHours);

        setTimeout(() => {
          const elem = document.querySelector('.contact-center');
          const searchElem = document.querySelector('.main-page.hotels');

          if (elem && searchElem) {
            (elem as any).style.display = 'block';
            searchElem.appendChild(elem);
          }
        }, 400);
      } catch (e) {}
    })();
  }, []); // eslint-disable-line

  return (
    <div>
      {workingHours && <ContactCenter workingHours={workingHours} />}
      <MainHotelPage />
    </div>
  );
};

export default MainCustomage;
